import * as React from 'react';
import LinkedInLogo from '../../assets/images/linkedin.inline.svg';
import TwitterLogo from '../../assets/images/twitter.inline.svg';
// import GithubLogo from '../../assets/images/github.inline.svg';
import SocialMediaLink from './SocialMediaLink';

export default function SocialMediaLinks() {
  return <>
    <SocialMediaLink url="https://www.linkedin.com/in/christianlehner">
      <LinkedInLogo />
    </SocialMediaLink>
    <SocialMediaLink url="https://www.twitter.com/chri_lehner">
      <TwitterLogo />
    </SocialMediaLink>
    {/* <SocialMediaLink url="https://www.github.com/lehnerchristian">
      <GithubLogo />
    </SocialMediaLink> */}
  </>;
}