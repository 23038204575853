import React from 'react';
import { graphql } from "gatsby";


import {LandingPageLayout} from '../components/layouts/LandingPageLayout';
import { MainLayout } from '../components/layouts/MainLayout';
import SEO from '../components/seo';
import LandingPage from '../components/landingPage/LandingPage';
import Header from '../components/header/Header';
import CV from '../components/cv/CV';
import Footer from '../components/footer/Footer';


import './index.css';

const IndexPage = ({data}) => (
  <div className="landing-page">
    <Header siteTitle={data.site.siteMetadata.title} />
    <LandingPageLayout>
      <SEO title="Home" />
      <LandingPage />
    </LandingPageLayout>
    <MainLayout>
      <CV></CV>
    </MainLayout>
    <Footer />
  </div>
);

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;


export default IndexPage;
