import React from 'react';

import style from './cvitem.module.css';

export default function CVItem(props) {
  return (
    <div className={style.cvItem}>
      <div className={style.timeline}>
        <div className={style.track}>
        </div>
        <div className={style.markWrapper}>
          <div className={style.mark}>
          </div>
        </div>
      </div>
      <article className={style.content}>
        {props.children}
      </article>
    </div>
  );
}