import React from 'react';
import CVItem from './CVItem';
import style from './cv.module.css';


export default function CV(props) {
  return (
    <section className={style.cv}>
      <div>
        <h1>Work Experience</h1>
        <CVItem>
          <h2>Dynatrace Austria GmbH (2021 - present)</h2>
          <h3>Software Engineer</h3>
          <p>
            At Dynatrace I work in the dashboarding team to create an amazing dashboarding experience for our customers. To be continued :-)
          </p>
          <h4>Tech Stack:</h4>
          <ul>
            <li>Frontend: TypeScript, React, Jest, React Testing Library</li>
          </ul>
        </CVItem>
        <CVItem>
          <h2>datavisyn GmbH (2017 - 2021)</h2>
          <h3>Software Engineer</h3>
          <p>
            At datavisyn I worked as a full-stack software engineer creating web applications for R&D departments of big pharmaceutical companies, such as Bayer and Boehringer Ingelheim.
            We worked closely with the end-users of our applications, mostly with bioinformaticians, biologists and chemists, to create a tool that suits their needs best to meet their business goals.
            Being the very first employee of this company I have been involved in most projects from the requirements engineering workshops at the beginning, to prototyping and building the applications.
          </p>
          <h4>Tech Stack:</h4>
          <ul>
            <li>Operations: Docker</li>
            <li>Backend: Python (Flask), PostgreSQL, SQLAlchemy</li>
            <li>Frontend: TypeScript, React, D3.js, Vega-Lite, SCSS</li>
          </ul>
        </CVItem>
        <CVItem>
          <h2>University of Salzburg (2016 - 2016)</h2>
          <h3>Student Research Assistant</h3>
          <p>
            At University of Salzburg I worked during my Master program on different little projects for the department of psychology for experiments. This included, for example a Visual Basic app to increase or decrease the size of food images based on the users' input with a joystick.
            The subject group was tasked to push high calory food away and pull low calory food near. I implemented this as a mobile app as well with Phonegap.
          </p>
          <h4>Tech Stack:</h4>
          <ul>
            <li>Frontend: Visual Basic, Phonegap app for Android (HTML, CSS, JavaScript)</li>
          </ul>
        </CVItem>
        <CVItem>
          <h2>Arkavis Siam; Bangkok, Thailand (2013 - 2013)</h2>
          <h3>Web Development Intern</h3>
          <p>
            In this internship in Bangkok I worked at a small company which mainly developed games for mobile phones for an Asian audience. My tasks were web development related, because the company also worked on some customer projects with Ruby on Rails.
            My last project for this company was to create a prototype of a Node.js multiplayer game server.
          </p>
          <h4>Tech Stack:</h4>
          <ul>
            <li>Backend: Ruby on Rails, Node.js, MongoDB, MySQL</li>
            <li>Frontend: HTML, CSS, JavaScript</li>
          </ul>
        </CVItem>
      </div>
      <div>
        <h1>Education</h1>
        <CVItem>
          <h2>University of Applied Science Salzburg (2014 - 2016)</h2>
          <h3>MSc.</h3>
          <p>
            During the Master program in MultimediaTechnology at University of Applied Science Salzburg (FH Salzburg) I had a courses about intelligent systems (recommender systems, machine learning), web development, visual analytics, algorithms, etc.
            Despite the courses we In a small team we had to work on a project on the course over a year in which we created the application "Vidatio", which was able to suggest visualizations based on the dataset the user uploaded.
            I wrote my master thesis about whether it is possible to measure the stress level of persons based on smartphone data.
          </p>
          <h4>Tech Stack:</h4>
          <ul>
            <li>Operations: Docker</li>
            <li>Backend: Ruby on Rails, Node.js</li>
            <li>Frontend: Angular.js, D3.js</li>
          </ul>
        </CVItem>
        <CVItem>
          <h2>University of Applied Science Salzburg (2011 - 2014)</h2>
          <h3>BSc.</h3>
          <p>
            In the Bachelor programme in MultimediaTechnology I my main focus was web development (HTML, CSS, JavaScript, PHP and Ruby on Rails).
            The secondary focus was based on building mobile applications for iOS with Objective-C.
            We also learned C++, C#, object-oriented programming, design patterns, etc.
          </p>
          <h4>Tech Stack:</h4>
          <ul>
            <li>Backend: Ruby on Rails, PHP</li>
            <li>Frontend: jQuery</li>
          </ul>
        </CVItem>
      </div>
      <div>
        <h1>Skills</h1>
        <ul className={style.skills}>
          <li>TypeScript</li>
          <li>React</li>
          <li>(S)CSS</li>
          <li>D3.js</li>
          <li>Node.js</li>
          <li>Ruby on Rails</li>
          <li>Python</li>
          <li>SQL</li>
          <li>Git</li>
          <li>Docker</li>
          <li>MongoDB</li>
          <li>Linux</li>
        </ul>
      </div>
    </section>
  );
}