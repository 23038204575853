import React from 'react';

import './footer.module.css'

export default function Footer() {
  return (
      <footer>
        <div>
          © {new Date().getFullYear()}, Christian Lehner; Nebelberg 31, 4155 Nebelberg; <a href="mailto:contact@lehnerchristian.com">contact@lehnerchristian.com</a>
        </div>
      </footer>
  );
}