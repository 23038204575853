import React from "react"

import style from "./landingPage.module.css"
import SocialMediaLinks from "../SocialMedia/SocialMediaLinks"

export default function LandingPage() {
  return <section className={style.landingSection}>
    <div>
      <div>
        <h2>Software Engineer</h2>
        <h1>Christian Lehner</h1>
        <p>Specialized in creating Websites and Web Apps</p>

        <div className={style.socialMediaLinks}>
          <SocialMediaLinks />
        </div>
      </div>
    </div>
  </section>;
}